
























































































import { InputSetups } from '@/mixins/input-setups'
import PlaceholdersMixin from "@/mixins/PlaceholdersMixin";

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import {
  UserActivationActionMessage, UserActivationActionTypeEnum,
} from "piramis-base-components/src/shared/modules/BotFeedback/ActivationAction";
import ActivationActionData from 'piramis-base-components/src/shared/modules/BotFeedback/ActivationAction/ActivationActionData.vue';

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component({
  components: {
    PageTitle,
    ConfigField,
    ActivationActionData,
    HighlightAnchor
  },
  data() {
    return {
      EntityTypes
    }
  }
})
export default class ChannelSettings extends Mixins(InputSetups, UseFields, PlaceholdersMixin) {

  actionSet = false

  get channel() {
    return this.$store.state.channelsState.activeChannel
  }

  setGoodbuyAction() {
    if (this.channel && !this.channel.config?.goodbuy_action) {
      const goodbuy_message = this.channel.config?.goodbuy_message

      if (goodbuy_message && goodbuy_message.length > 0) {
        const activationActionData: UserActivationActionMessage = {
          type: UserActivationActionTypeEnum.Message,
          variants: goodbuy_message
        }

        this.$set(this.channel?.config, 'goodbuy_action', activationActionData)
      } else {
        this.$set(this.channel?.config, 'goodbuy_action', null)
      }
    }

    this.actionSet = true
  }

  created() {
    this.setGoodbuyAction()
  }

  gotoFlows(): void {
    this.$router.push({
      name: 'Flows_List',
      params: {
        [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ]
      }
    })
  }
}
