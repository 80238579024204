

















import ChannelDetailedInformation from '@/components/ChannelDetailedInformation.vue'
import { TestChannelResultPayload } from '@/mixins/TestStateMixin'
import ChannelSettings from '@/components/ChannelSettings.vue'
import ChannelLicenseInformation from '@/components/ChannelLicenseInformation.vue'
import SaveChannelConfig from '@/components/SaveChannelConfig.vue'

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({
  components: {
    SaveChannelConfig,
    ChannelLicenseInformation,
    ChannelSettings,
    ChannelDetailedInformation,
    PageTitle,
  },
})
export default class ChannelMainSettings extends Vue {
  updateChannel(payload: TestChannelResultPayload):void {
    this.$store.commit('update_channel_info', payload.channel)
  }

  destroyed(): void {
    this.$store.dispatch('AdminTemplate/hideSaveConfigButton')
  }

  mounted(): void {
    this.$store.dispatch('AdminTemplate/showSaveConfigButton')
  }
}
